import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FurtherReading from "../components/furtherReading"

const Elise = () => (
  <Layout>
    <SEO
      title="First Year Doing it Herself - Elise's story"
      description="This week we had a chat with the amazing Elise Campbell. She has been looking after her own 4c hair for a year now after taking over from her mother"
    />
    <h1>The First Year Doing it Herself - Elise Talks Natural Hair</h1>

    <p>This week we had a chat with the amazing Elise Campbell. Elise aspires to be a business woman and is always questioning the world around her in an effort to understand it and make a change. She is also a fine photographer but doesn't talk to much about it. She has been doing her own hair for over a year after she agreed with her mother that she was ready.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/rkvQqPV.jpg"
        alt="Elise Campbell NAHC first year natural hair care"
        style={{marginBottom: "5px"}}
      />
      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}>Elise Campbell</p>
    </div>

    <h3>Hello Elise, tell us a bit about yourself</h3>
    <p>I am Elise Campbell and I am 12 years old. I was born in Nottingham , still live in Nottingham and I am a student.</p>

    <h3>What does your natural hair mean to you?</h3>
    <p>It means a lot of work but it also means individuality because most places I go I am either one of the few or only person that has natural hair.</p>

    <h3>How would you describe your hair?</h3>
    <p>I would describe my hair as all of the 4's (4 a b and c) because sometimes my hair is curly sometimes it has no curls at all and the back of my head is definitely more curly than the front. My hair isn't thick but I have a lot and it is very dry so I have to moisturise it a lot.</p>

    <h3>What is your family background?</h3>
    <p>You know this I think.</p>
    <p>EC - Both of Elise's parents have a Jamaican background.</p>

    <h3>Talk us through your hair routine</h3>
    <p>My hair routine is weird so what I do is I condition with a leave in conditioner so my hair isn't super dry throughout then I wash, sometimes I section but not always. I tend to focus on the roots more than anything. After washing I then rinse and comb with even more conditioner in my hair.</p>
    <p>Most days I have my hair in cornrows or little plaits if not it is most likely to be in two plaits down the side or in a puff made using the drawstring method.</p>

    <h3>How did you view your hair growing up?</h3>
    <p>My hair growing up wasn't too bad because I didn't do it i just hat to sit down and Mommy would do it. I quite liked it because it was different but other than that I didn't think about it much.</p>

    <h3>Were other people interested in your hair?</h3>
    <p>Yes because they had never seen hair like this and because some people with similar hair would ask what I used in it and things like that.</p>

    <h3>Did you have a role model growing up that helped you?</h3>
    <p>Not really but Tracee Ellis Ross has amazing hair.</p>

    <h3>Where did you learn to take care of your hair?</h3>
    <p>Mostly family. Mommy taught me how because she did my hair and I knew what she put in it and Danielle (Sister) was really good at hair and that so I wanted to be as good as her, I'm not as good as her though.</p>

    <h3>Any resources you use now?</h3>
    <p>Not really but I do watch a bunch of videos in different styles.</p>

    <h3>What happened when you started doing you hair yourself?</h3>
    <p>It wasn't easy but it wasn't difficult it was just a lot drier and had more knots but know I've learnt how to deal with that.</p>

    <h3>Any Interesting/funny Stories?</h3>
    <p>I got told I had a bald patch so I stopped doing my own hair and then I cried because I liked my hair next day I looked in the mirror my hair was fine.</p>

    <h3>Anything you wish you knew sooner?</h3>
    <p>That your hair is different to people's you see so you can't use other people's routine you have to find one that works for you.</p>

    <h3>What advice would you give for taking care of natural hair?</h3>
    <p>Find what works for your hair.</p>

    <h3>What is your favourite hairstyle right now?</h3>
    <p>Little plats.</p>

    <h3>Do you have any hair plans for the future?</h3>
    <p>Not really but it would be cool to teach people how to do their hair.</p>

    <h3>How can we follow/ contact you?</h3>
    <p>You can follow me @_eli_the_blob.</p>
    <p>EC - Elise is always changing her IG, message us if you want the latest</p>


    {/* <h2>Subtitle</h2> */}

    {/* <a
        href="https://example.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Example Link - for affiliate, add nofollow
      </a> */}


      <FurtherReading
      content={
        <div>
        <p>
            <Link to="/talking-natural-hair-with-celai-west/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
            Entrepreneurship, Modeling and Natural Hair with Celai West
            </Link>{" "}
            - We talk to the talented and incredibly hard working entrepreneur Celai West. Celai tells us about her unique homemade hair tea!...
          </p>
          <p>
            <Link to="/afro-hair-products/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
             The Best Afro Hair Products
            </Link>{" "}
            - The complete list of products you need for every step in your hair care routine...
          </p>          
        </div>
      }
    />

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default Elise
